import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Space, Divider, InputNumber, Checkbox } from 'antd';
import config from 'react-global-configuration';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getEstimateDuration, formatBalance, pluralize } from '../../../utils/utils';
import Href from '../../../shared/Components/Href';
import { Clock, Query, TaskList } from '../../../assets/icons';
import './TaskConfirmation.scss';
import { CustomAlert } from '../../../shared/Components/CustomAlert/CustomAlert.jsx';

const queriesSoftLimit = config.get('queriesSoftLimit');
const queriesHardLimit = config.get('queriesHardLimit');

const MAX_SPLIT = 30;
const DEFAULT_SPLIT = 1;
const queriesLimit = (queriesSoftLimit + 5000).toLocaleString();

export default function TaskConfirmationInfo({ splitInto = 1, setSplitInto, handleOk }) {
  const validationResult = useSelector(state => state.queriesReducer.taskValidationResult);
  const loading = useSelector(state => state.queriesReducer.loading);

  const { preview_queries = [], total_queries = 1, estimates = {}, enrich, positive_balance, power = false, input_file = null, limited } = validationResult;
  const { duration, results_amount, cost, cost_range, subtotal_cost, subtotal_cost_range, new_balance, services, discount_percent } = estimates;

  const previewCount = preview_queries.length;
  const more = (total_queries - previewCount).toLocaleString();
  const split = splitInto > 1;

  const queriesLimitWarning = total_queries > queriesSoftLimit && !split;
  const queriesLimitError = total_queries > queriesHardLimit && !split;

  const minSplit = Math.min(Math.max(Math.ceil(total_queries / queriesSoftLimit), 2), MAX_SPLIT);

  function handleShouldSplit() {
    if (split) {
      setSplitInto(DEFAULT_SPLIT);
    } else{
      setSplitInto(minSplit);
    }
  }

  const getItemLabel = (unit, count) => {
    return count === 1 ? unit : pluralize(unit);
  };

  if (loading) {
    return <Spin className='w-100' spinning={true}/>;
  }

  return <Space className='w-100' direction='vertical' size={16}>
    {input_file ? (
      <Space size={8} direction='vertical'>
        <Space size={4} direction='vertical'>
          <span className='main'>
          You&apos;re going to {enrich ? 'enrich the data inside' : 'extract the data from the queries inside'} the uploaded file:
          </span>
          <span className='bold'>{decodeURI(input_file)}</span>
        </Space>
        <p className='help-p1'>
            * <Trans i18nKey='description.queriesLimit'>Use up to {{ queriesLimit }} queries per single task to achieve better performance.</Trans>
        </p>
      </Space>
    ) : (
      <>
        <Space className='w-100' direction='vertical'>
          {estimates.free_tier_exceeded && !(positive_balance && new_balance < 0) &&
            <CustomAlert
              type='warning'
              message={<Trans i18nKey='message.exceededFreeTier'>
                <a target='_blank' rel='noopener noreferrer' href={config.get('pricingUrl')}>Free tier</a>
                &nbsp;might be exceeded. By starting the task, you agree with&nbsp;
                <a target='_blank' rel='noopener noreferrer' href={config.get('pricingUrl')}>pricing</a>
                &nbsp;and&nbsp;
                <a target='_blank' rel='noopener noreferrer' href={config.get('termsOfServiceUrl')}>terms of service</a>.
              </Trans>}
            />}
          {(results_amount || !!cost) &&
              <CustomAlert
                type='attention'
                message={<Trans i18nKey='description.roughEstimate'>This is a very rough estimate, the actual amount may vary. The cost depends on the volume of data actually received.</Trans>}
              />}
          {positive_balance && new_balance < 0 && !loading &&
            <CustomAlert
              type='warning'
              message={<Trans i18nKey='message.exceedCredits'>This task might exceed your credits. Starting confirms your agreement to potential additional billing.</Trans>}
            />
          }
          {queriesLimitWarning && !loading && !queriesLimitError &&
            <CustomAlert
              type='warning'
              message={<Trans i18nKey='message.splitTask'>It&apos;s recommended to split the task into subtasks in order to speed up the process and improve its reliability.</Trans>}
            />
          }
        </Space>
        <Space className='container' size={8} direction='vertical'>
          <div className='label'>
            <Query/>
            <h4 className='h4'><Trans i18nKey='title.confirmQueries'>Queries to be processed</Trans></h4>
          </div>

          {!loading && (
            <Space size={4} direction='vertical'>
              {preview_queries.slice(0, 3).map((r, index) => (
                <div key={index}>
                  <Href external href={r.link}>{r.title}</Href>
                </div>
              ))}
            </Space>
          )}
          {(total_queries > previewCount && !loading) && <p className='main-text'>... <Trans i18nKey='description.moreQueries'><span className='bold'>{{ more }}</span> more queries</Trans>.</p>}
        </Space>
        {services?.length !== 0 && (
          <Space className='container' size={8} direction='vertical'>
            <div className='label'>
              <TaskList/>
              <h4 className='h4'><Trans i18nKey='title.servicesAndEstimates'>Services and Estimates</Trans></h4>
            </div>

            <Space className='w-100' size={4} direction='vertical'>
              {services?.map(({ product_name, usage, cost, unit }, index) => {
                const unitLabel = getItemLabel(unit, usage);
                return (
                  <div className='block service' key={index}>
                    <div className='name'>
                      {product_name}
                      {index !== 0 && <span className='tag lowercase'> (<Trans i18nKey='title.enrichment'>Enrichment</Trans>)</span>}
                    </div>
                    <span className='usage'>
                      {estimates.rough && '~ '}{usage} <Trans i18nKey={`unit.${unitLabel}`}>{unitLabel}</Trans>
                    </span>
                    <span className='cost'>
                      {estimates.rough && '~ '}${cost}
                    </span>
                  </div>
                );
              })}
              <Divider/>
              <Space className='w-100' direction='vertical'>
                {discount_percent !== 0 && (
                  <>
                    <div className='block'>
                      <span className='title'><Trans i18nKey='title.subtotal'>Subtotal</Trans></span>
                      <span className='value'>
                    ${subtotal_cost_range && subtotal_cost_range.length === 2
                          ? `${subtotal_cost_range[0]} - ${subtotal_cost_range[1]}`
                          : `${subtotal_cost}`
                        }
                      </span>
                    </div>
                    <div className='block discount'>
                      <span className='title'><Trans i18nKey='title.discount'>Discount</Trans></span>
                      <span className='value'>{discount_percent}%</span>
                    </div>
                  </>
                )}
                <div className='block bold'>
                  <span className='title'><Trans i18nKey='title.total'>Total</Trans></span>
                  <span className='value'>
                ${cost_range && cost_range.length === 2
                      ? `${cost_range[0]} - ${cost_range[1]}`
                      : `${cost}`
                    }
                  </span>
                </div>
                {cost && (new_balance && new_balance < 0) ? (
                  <div className='block'>
                    <span className='title'><Trans i18nKey='description.estimatedBalance'>Estimated balance after finished task</Trans></span>
                    <span className='value bold'>{formatBalance(new_balance)}</span>
                  </div>
                ) : null}
              </Space>
            </Space>
          </Space>
        )}

        {duration &&
            <div className='time-estimate'>
              <div className='label'>
                <Clock/>
                <Trans i18nKey='title.estimatedCompletionTime'>Estimated Completion Time</Trans>:
              </div>
              <span className='bold'>
                {getEstimateDuration(duration, splitInto)}
              </span>
            </div>
        }
      </>
    )}
    {queriesLimitError && !loading &&
        <CustomAlert
          type='error'
          message={<><Trans i18nKey='message.splitTaskReqired'>Please split the task into subtasks to reduce the number of queries per task below</Trans> <strong>{queriesHardLimit.toLocaleString()}</strong>.</>}
        />
    }
    {split &&
        <CustomAlert
          type='warning'
          message={<><Trans i18nKey='message.splitedTask'>Your task will be divided into <strong>{{ splitInto }}</strong>+ subtasks. Be aware that some features like <strong>Delete duplicates</strong>, <strong>Results limit</strong>, etc. might work only inside each task.</Trans></>}
        />
    }
    {power && ((total_queries >= queriesSoftLimit && total_queries <= queriesSoftLimit * MAX_SPLIT && !limited) ||
        (input_file && (!duration || duration > 60 * 6))) ? (
        <div key='split'>
          <Checkbox
            key='split'
            checked={split}
            onChange={handleShouldSplit}
            disabled={loading}
          >
            <Trans i18nKey='action.splitIntoSubtasks'>Split into subtasks</Trans>
          </Checkbox>
          {input_file && split && (
            <InputNumber
              style={{ width: 55 }}
              value={splitInto}
              size='small'
              min={2}
              max={10}
              step={1}
              onChange={setSplitInto}
              onPressEnter={handleOk}
            />
          )}
        </div>
      ) : null}
  </Space>;
}

TaskConfirmationInfo.propTypes = {
  splitInto: PropTypes.number,
  setSplitInto: PropTypes.func,
  handleOk: PropTypes.func,
};
