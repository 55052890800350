import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ErrorMessage = ({ message }) => {
  const { t } = useTranslation();
  const displayMessage = message || t('description.fieldRequired', 'This field is required.');

  return (
    <div className='error-message'>
      {displayMessage}
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string
};
