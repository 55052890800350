import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Layout } from 'antd';
import styled from 'styled-components';
import config from 'react-global-configuration';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';

import configureStore from './redux/store';
import Auth from './auth/Auth';
import Signup from './auth/Signup';
import ReferrerRedirect from './auth/ReferrerRedirect';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import ResetPasswordConfirm from './auth/ResetPasswordConfirm';
import KYC from './auth/KYC';
import Logout from './auth/Logout';
import Subsribe from './auth/Subsribe';
import VerifyEmail from './auth/VerifyEmail';
import PrivateRoute from './utils/PrivateRoute';
import Sider from './Components/Navigation/Sider';
import PlacesByDomainsSearch from './Components/Services/PlacesByDomainsSearch';
import PlacesSearch from './Components/Services/PlacesSearch';
import PlacesUpdate from './Components/Services/PlacesUpdate';
import GoogleSearch from './Components/Services/GoogleSearch/GoogleSearch';
import GoogleSearchShopping from './Components/Services/GoogleSearch/GoogleSearchShopping';
import GoogleSearchImages from './Components/Services/GoogleSearch/GoogleSearchImages';
import GoogleSearchNews from './Components/Services/GoogleSearch/GoogleSearchNews';
import GoogleSearchVideos from './Components/Services/GoogleSearch/GoogleSearchVideos';
import GoogleSearchCareers from './Components/Services/GoogleSearch/GoogleSearchCareers';
import GoogleSearchJobs from './Components/Services/GoogleSearch/GoogleSearchJobs';
import GoogleSearchEvents from './Components/Services/GoogleSearch/GoogleSearchEvents';
import GoogleMapsDirectoryPlaces from './Components/Services/GoogleSearch/GoogleMapsDirectoryPlaces';
import GoogleMapsReviewsExtractor from './Components/Services/GoogleMapsReviews';
import GoogleMapsContributorReviews from './Components/Services/GoogleMapsContributorReviews';
import GoogleMapsPhotos from './Components/Services/GoogleMapsPhotos';
import GooglePlayReviews from './Components/Services/GooglePlayReviews';
import AppStoreReviews from './Components/Services/AppStoreReviews';
import AirbnbReviews from './Components/Services/AirbnbReviews';
import AirbnbSearch from './Components/Services/AirbnbSearch';
import GoogleProductsReviews from './Components/Services/GoogleProductsReviews';
import GoogleTrends from './Components/Services/GoogleTrends';
import DomainContacts from './Components/Services/Enrichers/Lookup/DomainContacts/DomainContacts';
import EmailValidator from './Components/Services/Enrichers/Lookup/EmailValidator/EmailValidator';
import FreeEmailsValidator from './Components/Services/Enrichers/Lookup/EmailValidator/EmailsValidator';
import FreeEmailsFinder from './Components/Services/Enrichers/Lookup/EmailsFinder';
import GoogleMapsTrafficExtractor from './Components/Services/GoogleMapsTrafficExtractor';
import AmazonProductsExtractor from './Components/Services/AmazonProductsExtractor';
import AmazonReviewsExtractor from './Components/Services/AmazonReviewsExtractor';
import ShopifyProductsScraper from './Components/Services/ShopifyProductsScraper';
import ShopeeReviews from './Components/Services/ShopeeReviews';
import EbayProducts from './Components/Services/EbayProducts';
import DomainsExtractor from './Components/Services/DomainsExtractor';
import ContactsFinder from './Components/Services/ContactsFinder';
import CompanyInsights from './Components/Services/CompanyInsights';
import USPhonesEnricher from './Components/Services/Enrichers/USPhonesEnricher';
import PhonesEnricher from './Components/Services/Enrichers/PhonesEnricher';
import WhatsappChecker from './Components/Services/Enrichers/WhatsappChecker';
import WhatsappSender from './Components/Services/Actors/WhatsappSender';
import ImessageChecker from './Components/Services/Enrichers/ImessageChecker';
import HubSpotContactEmailsFinder from './Components/Services/Enrichers/HubSpotContactEmailsFinder';
import HubSpotCompanyContactsFinder from './Components/Services/Enrichers/HubSpotCompanyContactsFinder';
import EmailsValidator from './Components/Services/Validators/EmailsValidator';
import EmailFinder from './Components/Services/Enrichers/EmailFinder';
import DisposableEmailChecker from './Components/Services/Validators/DisposableEmailChecker';
import YelpScraper from './Components/Services/YelpScraper';
import ZillowSearch from './Components/Services/ZillowSearch';
import YellowPagesSearchScraper from './Components/Services/YellowPagesSearchScraper';
import YelpReviewsScraper from './Components/Services/YelpReviewsScraper';
import YelpPhotosScraper from './Components/Services/YelpPhotosScraper';
import BookingReviewsScraper from './Components/Services/BookingReviewsScraper';
import BookingSearchScraper from './Components/Services/BookingSearchScraper';
import BookingPricesScraper from './Components/Services/BookingPricesScraper';
import TargetReviewsScraper from './Components/Services/TargetReviewsScraper';
import ExpediaSearchScraper from './Components/Services/ExpediaSearchScraper';
import ExpediaReviewsScraper from './Components/Services/ExpediaReviewsScraper';
import GetappReviewsScraper from './Components/Services/GetappReviewsScraper';
import G2ReviewsScraper from './Components/Services/G2ReviewsScraper';
import IndeedSearchScraper from './Components/Services/IndeedSearchScraper';
import IndeedReviewsScraper from './Components/Services/IndeedReviewsScraper';
import GlassdoorSearchScraper from './Components/Services/GlassdoorSearchScraper';
import GlassdoorReviewsScraper from './Components/Services/GlassdoorReviewsScraper';
import HomedepotReviews from './Components/Services/HomedepotReviews';
import WalmartReviews from './Components/Services/WalmartReviews';
import TrustpilotScraper from './Components/Services/TrustpilotScraper';
import TrustpilotReviewsScraper from './Components/Services/TrustpilotReviewsScraper';
import TrustpilotSearchScraper from './Components/Services/TrustpilotSearchScraper';
import TripadvisorReviewsScraper from './Components/Services/TripadvisorReviewsScraper';
import TripadvisorSearch from './Components/Services/TripadvisorSearch';
import LinkedinCompaniesScraper from './Components/Services/LinkedinCompaniesScraper';
import LinkedinPeopleScraper from './Components/Services/LinkedinPeopleScraper';
import LinkedinPostsScraper from './Components/Services/LinkedinPostsScraper';
import Geocoding from './Components/Services/Geocoding';
import ReverseGeocoding from './Components/Services/ReverseGeocoding';
import FacebookPagesScraper from './Components/Services/FacebookPagesScraper';
import TwitterProfilesScraper from './Components/Services/TwitterProfilesScraper';
import TiktokProfilesScraper from './Components/Services/TiktokProfilesScraper';
import InstagramProfilesScraper from './Components/Services/InstagramProfilesScraper';
import FacebookSearchScraper from './Components/Services/FacebookSearchScraper';
import OnlyfansProfilesScraper from './Components/Services/OnlyfansProfilesScraper';
import YouTubeCommentsScraper from './Components/Services/YouTubeCommentsScraper';
import YouTubeChannelsScraper from './Components/Services/YouTubeChannelsScraper';
import YouTubeSearchScraper from './Components/Services/YouTubeSearchScraper';
import FacebookReviews from './Components/Services/FacebookReviews';
import CapterraReviews from './Components/Services/CapterraReviews';
import ProducthuntReviews from './Components/Services/ProducthuntReviews';
import Linkedin from './Components/Services/Linkedin';
import SMSMessaging from './Components/Services/Actors/SMSMessaging';
import AIScraper from './Components/Services/AIScraper';
import WhitepagesPhones from './Components/Services/WhitepagesPhones';
import WhitepagesAddresses from './Components/Services/WhitepagesAddresses';
import FastbackgroundcheckAddresses from './Components/Services/FastbackgroundcheckAddresses';
import UniversalAIScraper from './Components/Services/UniversalAIScraper';
import Screenshoter from './Components/Services/Screenshoter';
import DomainContactsFinder from './Components/Services/DomainContactsFinder';
import NotYetCreatedService from './Components/Services/Actors/NotYetCreatedService';
import Tasks from './Components/Tasks/Tasks';
import SharedTaskResults from './Components/Tasks/SharedTaskResults';
import Profile from './Components/Profile/Profile';
import Invoices from './Components/Invoices/Invoices';
import BillingInformation from './Components/Profile/BillingInformation';
import ApiUsage from './Components/API/ApiUsage';
import ApiDocs from './Components/API/ApiDocs';
import WebhookCalls from './Components/WebhookCalls/WebhookCalls';
import MakeCryptoPayment from './Components/Profile/AddCredits/Crypto/MakeCryptoPayment';
import CryptoPayment from './Components/Profile/AddCredits/Crypto/CryptoPayment';
import Integrations from './Components/Integrations/Integrations';
import HotelsReviewsScraper from './Components/Services/HotelsReviewsScraper';
import HotelsSearchScraper from './Components/Services/HotelsSearchScraper';
import { addUrlParams } from './utils/utils';
import GoogleMapsScraper from './Components/Services/GoogleMaps/GoogleMaps';

const StyledDiv = styled.div`
  background: #fff;
  padding: 0 25px 0;
  min-height: 100%;
`;

const auth = new Auth();
const googleClientId = config.get('googleClientId');

const PLAIN_PAGES = new Set([
  '/domain-contacts-lookup',
  '/email-validator-lookup',
  '/emails-validator-lookup',
  '/r',
  '/refer',
  '/signup-confirm',
  '/emails-finder-lookup',
  '/login',
  '/signup',
  '/reset-password',
]);

export default function App() {
  const location = useLocation();
  const { pathname } = location;
  const { profile } = auth;
  const { ie } = profile;

  const showSider = !PLAIN_PAGES.has('/' + pathname.split('/')[1]) && !pathname.includes('/shared/');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn') === '1') {
      const expiresAt = localStorage.getItem('expiresAt');

      if (expiresAt) {
        const difference = (Number(expiresAt) - new Date().getTime()) / 1000 / 24 / 60 / 60;
        if (difference < 29) {
          auth.renewSession();
        }
      }
    }

    if (showSider || pathname === '/login' || pathname === '/signup') {
      const language = i18n.language.split('-')[0];
      const newParams = addUrlParams(location.search, { ln: language === 'en' ? null : language }, true);
      window.history.replaceState(null, null, newParams ? `?${newParams}` : window.location.pathname);
    }

    const intervalId = setInterval(
      () => {
        if (localStorage.getItem('isLoggedIn') === '1') {
          auth.renewSession();
        }
      },
      1000 * 60 * 60 * 12
    );

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Provider store={configureStore()}>
      {ie && <>God Mode: {ie}</>}
      {showSider && (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider auth={auth} />
          <Layout>
            <Layout.Content style={{ paddingLeft: '20px' }}>
              <StyledDiv>
                <Routes>
                  <Route path='/' element={<Navigate to='/profile' />} />
                  <Route
                    path='/googleMaps'
                    element={<PrivateRoute element={GoogleMapsScraper} auth={auth} version={2} />}
                  />
                  <Route
                    path='/google-maps'
                    element={<PrivateRoute element={GoogleMapsScraper} auth={auth} />}
                  />
                  <Route
                    path='/google-places-domain-search'
                    element={<PrivateRoute element={PlacesByDomainsSearch} auth={auth} />}
                  />
                  <Route
                    path='/places-update'
                    element={<PrivateRoute element={PlacesUpdate} auth={auth} />}
                  />
                  <Route
                    path='/places-search'
                    element={<PrivateRoute element={PlacesSearch} auth={auth} />}
                  />
                  <Route
                    path='/google-search'
                    element={<PrivateRoute element={GoogleSearch} auth={auth} />}
                  />
                  <Route
                    path='/google-search-shopping'
                    element={<PrivateRoute element={GoogleSearchShopping} auth={auth} />}
                  />
                  <Route
                    path='/google-search-images'
                    element={<PrivateRoute element={GoogleSearchImages} auth={auth} />}
                  />
                  <Route
                    path='/google-search-news'
                    element={<PrivateRoute element={GoogleSearchNews} auth={auth} />}
                  />
                  <Route
                    path='/google-search-videos'
                    element={<PrivateRoute element={GoogleSearchVideos} auth={auth} />}
                  />
                  <Route
                    path='/google-search-events'
                    element={<PrivateRoute element={GoogleSearchEvents} auth={auth} />}
                  />
                  <Route
                    path='/google-search-jobs'
                    element={<PrivateRoute element={GoogleSearchJobs} auth={auth} />}
                  />
                  <Route
                    path='/google-careers'
                    element={<PrivateRoute element={GoogleSearchCareers} auth={auth} />}
                  />
                  <Route
                    path='/google-directory-places'
                    element={<PrivateRoute element={GoogleMapsDirectoryPlaces} auth={auth} />}
                  />
                  <Route
                    path='/googleReviews'
                    element={<PrivateRoute element={GoogleMapsReviewsExtractor} auth={auth} version={3} />}
                  />
                  <Route
                    path='/google-maps-reviews'
                    element={<PrivateRoute element={GoogleMapsReviewsExtractor} auth={auth} />}
                  />
                  <Route
                    path='/google-maps-contributor-reviews'
                    element={<PrivateRoute element={GoogleMapsContributorReviews} auth={auth} />}
                  />
                  <Route
                    path='/google-maps-photos'
                    element={<PrivateRoute element={GoogleMapsPhotos} auth={auth} />}
                  />
                  <Route
                    path='/google-play-reviews'
                    element={<PrivateRoute element={GooglePlayReviews} auth={auth} />}
                  />
                  <Route
                    path='/appstore-reviews'
                    element={<PrivateRoute element={AppStoreReviews} auth={auth} />}
                  />
                  <Route
                    path='/airbnb-search'
                    element={<PrivateRoute element={AirbnbSearch} auth={auth} />}
                  />
                  <Route
                    path='/airbnb-reviews'
                    element={<PrivateRoute element={AirbnbReviews} auth={auth} />}
                  />
                  <Route
                    path='/google-shopping-reviews'
                    element={<PrivateRoute element={GoogleProductsReviews} auth={auth} />}
                  />
                  <Route
                    path='/googleTraffic'
                    element={<PrivateRoute element={GoogleMapsTrafficExtractor} auth={auth} />}
                  />
                  <Route
                    path='/google-traffic'
                    element={<PrivateRoute element={GoogleMapsTrafficExtractor} auth={auth} />}
                  />
                  <Route
                    path='/google-trends'
                    element={<PrivateRoute element={GoogleTrends} auth={auth} />}
                  />
                  <Route
                    path='/amazon-products'
                    element={<PrivateRoute element={AmazonProductsExtractor} auth={auth} />}
                  />
                  <Route
                    path='/amazon-reviews'
                    element={<PrivateRoute element={AmazonReviewsExtractor} auth={auth} />}
                  />
                  <Route
                    path='/shopify-products'
                    element={<PrivateRoute element={ShopifyProductsScraper} auth={auth} />}
                  />
                  <Route
                    path='/shopee-reviews'
                    element={<PrivateRoute element={ShopeeReviews} auth={auth} />}
                  />
                  <Route
                    path='/ebay-products'
                    element={<PrivateRoute element={EbayProducts} auth={auth} />}
                  />
                  <Route
                    path='/emails-scraper'
                    element={<PrivateRoute element={DomainsExtractor} auth={auth} />}
                  />
                  <Route
                    path='/contacts-finder'
                    element={<PrivateRoute element={ContactsFinder} auth={auth} />}
                  />
                  <Route
                    path='/company-insights'
                    element={<PrivateRoute element={CompanyInsights} auth={auth} />}
                  />
                  <Route
                    path='/domains-scraper'
                    element={<PrivateRoute element={DomainsExtractor} auth={auth} />}
                  />
                  <Route
                    path='/phones-enricher'
                    element={<PrivateRoute element={PhonesEnricher} auth={auth} />}
                  />
                  <Route
                    path='/us-phones-enricher'
                    element={<PrivateRoute element={USPhonesEnricher} auth={auth} />}
                  />
                  <Route
                    path='/whatsapp-checker'
                    element={<PrivateRoute element={WhatsappChecker} auth={auth} />}
                  />
                  <Route
                    path='/whitepages-phones'
                    element={<PrivateRoute element={WhitepagesPhones} auth={auth} />}
                  />
                  <Route
                    path='/whitepages-addresses'
                    element={<PrivateRoute element={WhitepagesAddresses} auth={auth} />}
                  />
                  <Route
                    path='/fastbackgroundcheck-addresses'
                    element={<PrivateRoute element={FastbackgroundcheckAddresses} auth={auth} />}
                  />
                  <Route
                    path='/whatsapp-sender'
                    element={<PrivateRoute element={WhatsappSender} auth={auth} />}
                  />
                  <Route
                    path='/imessage-checker'
                    element={<PrivateRoute element={ImessageChecker} auth={auth} />}
                  />
                  <Route
                    path='/messaging'
                    element={<PrivateRoute element={SMSMessaging} auth={auth} />}
                  />
                  <Route
                    path='/emailing'
                    element={<PrivateRoute element={NotYetCreatedService} auth={auth} title='Bulk Emailing' subTitle='Sends emails to a list of recipients' />}
                  />
                  <Route
                    path='/imessage-sender'
                    element={<PrivateRoute element={NotYetCreatedService} auth={auth} title='iMessage Sender' subTitle='Sends messages to a list of recipients via iMessage' />}
                  />
                  <Route
                    path='/telegram-checker'
                    element={<PrivateRoute element={NotYetCreatedService} auth={auth} title='Telegram Checker' subTitle='Checks whether a phone is connected to Telegram' />}
                  />
                  <Route
                    path='/wechat-checker'
                    element={<PrivateRoute element={NotYetCreatedService} auth={auth} title='WeChat Checker' subTitle='Checks whether a phone is connected to WeChat' />}
                  />
                  <Route
                    path='/emails-validator'
                    element={<PrivateRoute element={EmailsValidator} auth={auth} />}
                  />
                  <Route
                    path='/email-finder'
                    element={<PrivateRoute element={EmailFinder} auth={auth} />}
                  />
                  <Route
                    path='/disposable-email-checker'
                    element={<PrivateRoute element={DisposableEmailChecker} auth={auth} />}
                  />
                  <Route
                    path='/linkedin'
                    element={<PrivateRoute element={Linkedin} auth={auth} />}
                  />
                  <Route
                    path='/yelp'
                    element={<PrivateRoute element={YelpScraper} auth={auth} />}
                  />
                  <Route
                    path='/yelp-reviews'
                    element={<PrivateRoute element={YelpReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/yelp-photos'
                    element={<PrivateRoute element={YelpPhotosScraper} auth={auth} />}
                  />
                  <Route
                    path='/booking-search'
                    element={<PrivateRoute element={BookingSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/booking-reviews'
                    element={<PrivateRoute element={BookingReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/booking-prices'
                    element={<PrivateRoute element={BookingPricesScraper} auth={auth} />}
                  />
                  <Route
                    path='/expedia-search'
                    element={<PrivateRoute element={ExpediaSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/expedia-reviews'
                    element={<PrivateRoute element={ExpediaReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/getapp-reviews'
                    element={<PrivateRoute element={GetappReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/g2-reviews'
                    element={<PrivateRoute element={G2ReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/target-reviews'
                    element={<PrivateRoute element={TargetReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/homedepot-reviews'
                    element={<PrivateRoute element={HomedepotReviews} auth={auth} />}
                  />
                  <Route
                    path='/hotels-search'
                    element={<PrivateRoute element={HotelsSearchScraper} auth={auth}/>}
                  />
                  <Route
                    path='/hotels-reviews'
                    element={<PrivateRoute element={HotelsReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/walmart-reviews'
                    element={<PrivateRoute element={WalmartReviews} auth={auth} />}
                  />
                  <Route
                    path='/indeed-search'
                    element={<PrivateRoute element={IndeedSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/indeed-reviews'
                    element={<PrivateRoute element={IndeedReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/glassdoor-search'
                    element={<PrivateRoute element={GlassdoorSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/glassdoor-reviews'
                    element={<PrivateRoute element={GlassdoorReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/yellowpages'
                    element={<PrivateRoute element={YellowPagesSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/trustpilot'
                    element={<PrivateRoute element={TrustpilotScraper} auth={auth} />}
                  />
                  <Route
                    path='/trustpilot-reviews'
                    element={<PrivateRoute element={TrustpilotReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/trustpilot-search'
                    element={<PrivateRoute element={TrustpilotSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/zillow-search'
                    element={<PrivateRoute element={ZillowSearch} auth={auth} />}
                  />
                  <Route
                    path='/tripadvisor-reviews'
                    element={<PrivateRoute element={TripadvisorReviewsScraper} auth={auth} />}
                  />
                  <Route
                    path='/tripadvisor-search'
                    element={<PrivateRoute element={TripadvisorSearch} auth={auth} />}
                  />
                  <Route
                    path='/linkedin-companies'
                    element={<PrivateRoute element={LinkedinCompaniesScraper} auth={auth} />}
                  />
                  <Route
                    path='/linkedin-profiles'
                    element={<PrivateRoute element={LinkedinPeopleScraper} auth={auth} />}
                  />
                  <Route
                    path='/linkedin-posts'
                    element={<PrivateRoute element={LinkedinPostsScraper} auth={auth} />}
                  />
                  <Route
                    path='/facebook-pages'
                    element={<PrivateRoute element={FacebookPagesScraper} auth={auth} />}
                  />
                  <Route
                    path='/facebook-search'
                    element={<PrivateRoute element={FacebookSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/facebook-reviews'
                    element={<PrivateRoute element={FacebookReviews} auth={auth} />}
                  />
                  <Route
                    path='/instagram-profiles'
                    element={<PrivateRoute element={InstagramProfilesScraper} auth={auth} />}
                  />
                  <Route
                    path='/twitter-profiles'
                    element={<PrivateRoute element={TwitterProfilesScraper} auth={auth} />}
                  />
                  <Route
                    path='/tiktok-profiles'
                    element={<PrivateRoute element={TiktokProfilesScraper} auth={auth} />}
                  />
                  <Route
                    path='/capterra-reviews'
                    element={<PrivateRoute element={CapterraReviews} auth={auth} />}
                  />
                  <Route
                    path='/producthunt-reviews'
                    element={<PrivateRoute element={ProducthuntReviews} auth={auth} />}
                  />
                  <Route
                    path='/onlyfans-profiles'
                    element={<PrivateRoute element={OnlyfansProfilesScraper} auth={auth} />}
                  />
                  <Route
                    path='/youtube-comments'
                    element={<PrivateRoute element={YouTubeCommentsScraper} auth={auth} />}
                  />
                  <Route
                    path='/youtube-channels'
                    element={<PrivateRoute element={YouTubeChannelsScraper} auth={auth} />}
                  />
                  <Route
                    path='/youtube-search'
                    element={<PrivateRoute element={YouTubeSearchScraper} auth={auth} />}
                  />
                  <Route
                    path='/hubspot-contact-emails-finder'
                    element={<PrivateRoute element={HubSpotContactEmailsFinder} auth={auth} />}
                  />
                  <Route
                    path='/hubspot-company-contacts-finder'
                    element={<PrivateRoute element={HubSpotCompanyContactsFinder} auth={auth} />}
                  />
                  <Route
                    path='/ai-scraper'
                    element={<PrivateRoute element={AIScraper} auth={auth} />}
                  />
                  <Route
                    path='/universal-scraper'
                    element={<PrivateRoute element={UniversalAIScraper} auth={auth} />}
                  />
                  <Route
                    path='/screenshoter'
                    element={<PrivateRoute element={Screenshoter} auth={auth} />}
                  />
                  <Route
                    path='/geocoding'
                    element={<PrivateRoute element={Geocoding} auth={auth} />}
                  />
                  <Route
                    path='/reverse-geocoding'
                    element={<PrivateRoute element={ReverseGeocoding} auth={auth} />}
                  />
                  <Route
                    path='/domain-contacts-finder'
                    element={<PrivateRoute element={DomainContactsFinder} auth={auth} />}
                  />
                  <Route
                    path='/profile'
                    element={<PrivateRoute element={Profile} auth={auth} authForwarded={auth} />}
                  />
                  <Route
                    path='/profile/crypto-payment'
                    element={<PrivateRoute element={MakeCryptoPayment} auth={auth} />}
                  />
                  <Route
                    path='/profile/crypto-payment/:paymentId'
                    element={<PrivateRoute element={CryptoPayment} auth={auth} />}
                  />
                  <Route
                    path='/invoices'
                    element={<PrivateRoute element={Invoices} auth={auth} />}
                  />
                  <Route
                    path='/billing-info'
                    element={<PrivateRoute element={BillingInformation} auth={auth} authForwarded={auth} />}
                  />
                  <Route
                    path='/api-usage'
                    element={<PrivateRoute element={ApiUsage} auth={auth} />}
                  />
                  <Route
                    path='/webhook-calls'
                    element={<PrivateRoute element={WebhookCalls} auth={auth} />}
                  />
                  <Route
                    path='/tasks'
                    element={<PrivateRoute element={Tasks} auth={auth} />}
                  />

                  <Route exact path='/integrations' element={<Integrations auth={auth}/>} />
                  <Route exact path='/api-docs' element={<ApiDocs auth={auth}/>} />

                  <Route exact path='/logout' element={<Logout auth={auth}/>} />
                  <Route exact path='/callback' element={<Logout auth={auth}/>} />
                  <Route exact path='/verify-email/:token' element={<VerifyEmail />}  />
                  <Route
                    exact
                    path='/subsribe'
                    element={<Subsribe auth={auth}/>}
                  />
                </Routes>
              </StyledDiv>
            </Layout.Content>
          </Layout>
        </Layout>
      )}
      <GoogleOAuthProvider clientId={googleClientId}>
        <Routes>
          <Route exact path='/login' element={<Login auth={auth} />} />
          <Route exact path='/signup' element={<Signup auth={auth} />} />
        </Routes>
      </GoogleOAuthProvider>
      <Routes>
        <Route
          exact
          path='/reset-password'
          element={<ResetPassword />}
        />
        <Route
          exact
          path='/reset-password/:token'
          element={<ResetPasswordConfirm />}
        />
        <Route exact path='/r' element={<ReferrerRedirect />} />
        <Route exact path='/refer' element={<ReferrerRedirect />} />
        <Route exact path='/signup-confirm' element={<KYC auth={auth} />} />
        <Route exact path='/shared/:userId/:taskId' element={<SharedTaskResults />} />
        <Route exact path='/domain-contacts-lookup' element={<DomainContacts />} />
        <Route exact path='/email-validator-lookup' element={<EmailValidator />} />
        <Route exact path='/emails-validator-lookup' element={<FreeEmailsValidator />} />
        <Route exact path='/emails-finder-lookup' element={<FreeEmailsFinder/>} />
      </Routes>
    </Provider>
  );
}
