import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import ExtractorHeader from '../Common/ExtractorHeader';
import FeatureRequestModal from '../../Others/FeatureRequestModal';

export default function NotYetCreatedService({ title, subTitle }) {
  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);

  useEffect(() => {
    document.title = title + ' | Outscraper';
  }, []);

  return (
    <>
      <ExtractorHeader
        title={title}
        localTitle={title}
        subTitle={subTitle}
      />
      This service is being created. Please, let us know if you are interested so we can prioritize this task.<br/><br/>
      <Button
        type='primary'
        onClick={() => setFeatureRequestOpen(true)}
      >I&apos;m Interested</Button>
      <FeatureRequestModal
        title='Tell More About Your Tasks'
        open={featureRequestOpen}
        onChange={setFeatureRequestOpen}
      />
    </>
  );
}

NotYetCreatedService.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};
