import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';

import './StartTaskButton.scss';

export default function StartTaskButton({ onClick, loading, title=null, icon = <DownloadOutlined/> }) {
  const { t } = useTranslation();

  return (
    <div  className='start-task-btn-wrapper'>
      <Button
        className='start-task'
        type='primary'
        size='large'
        icon={icon}
        onClick={onClick}
        loading={loading}
      >{title ? title : t('title.startTask', 'Get Data')}</Button>
    </div>
  );
}

StartTaskButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.object,
};
