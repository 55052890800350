import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const fontSizeMapping = {
  small: { price: '14px', detail: '12px', features: '11px', margin: '-7px' },
  default: { price: '20px', detail: '14px', features: '12px', margin: '10px' },
  big: { price: '22px', detail: '18px', features: '16px', margin: '12px' }
};

const StyledCard = styled.div`
  margin-left: 10px;
  margin-right: 50px;
  margin-bottom: ${props => fontSizeMapping[props.size].margin};
  margin-top: ${props => fontSizeMapping[props.size].margin};
`;

const PriceLabel = styled.span`
  color: #C41230;
  font-size: ${props => fontSizeMapping[props.size].price};
`;

const PriceDetailLabel = styled.span`
  font-size: ${props => fontSizeMapping[props.size].detail};
`;

const PriceFeaturesLabel = styled.span`
  font-size: ${props => fontSizeMapping[props.size].features};
`;

export default function PricingCards({ value, unitName = 'item', cardStyle = {}, size = 'default' }) {
  const prices = value || [];

  return (
    <>
      {prices.map(({ up_to, unit_amount_decimal }, i) => {
        const nextFrom = i > 0 ? prices[i-1].up_to : null;

        return <StyledCard size={size} style={cardStyle} key={`tire-${i+1}`}>
          <PriceDetailLabel size={size}>$<PriceLabel size={size}>{unit_amount_decimal}</PriceLabel>/{unitName}</PriceDetailLabel><br/>
          {i === 0 ? (
            <PriceFeaturesLabel size={size}><Trans i18nKey='description.forTheFirst'>For the first 1 to {{ up_to }}</Trans></PriceFeaturesLabel>
          ) : (
            up_to ? <PriceFeaturesLabel size={size}><Trans i18nKey='description.forTheNext'>For the next {{ nextFrom }} to {{ up_to }}</Trans></PriceFeaturesLabel> : <PriceFeaturesLabel size={size}><Trans i18nKey='description.forTheRest'>For the rest</Trans></PriceFeaturesLabel>
          )}
        </StyledCard>;
      })}
    </>
  );
}

PricingCards.propTypes = {
  value: PropTypes.array.isRequired,
  unitName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cardStyle: PropTypes.object,
  size: PropTypes.oneOf(['small', 'default', 'big']),
};
