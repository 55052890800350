import React from 'react';
import PropTypes from 'prop-types';
import { Select, Space } from 'antd';

import InfoTooltip from '../../../../shared/Components/InfoTooltip';

const { Option } = Select;

export default function GoogleDateRangeSelect({ value, onChange, hideTooltip, allowClear }) {
  function handleOnChange(value) {
    onChange({ tbs: value });
  }

  return  <Space align='center'>
    <Select
      allowClear={allowClear}
      showSearch
      style={{ width: 200 }}
      value={value || ''}
      optionFilterProp='children'
      onChange={handleOnChange}
    >
      <Option key='any' value=''>Any time</Option>
      <Option key='h' value='h'>Past hour</Option>
      <Option key='d' value='d'>Past 24 hours</Option>
      <Option key='w' value='w'>Past week</Option>
      <Option key='m' value='m'>Past month</Option>
      <Option key='y' value='y'>Past year</Option>
    </Select>
    {!hideTooltip &&
      <InfoTooltip title='Parameter specifies the date range.'/>
    }
  </Space>;
}

GoogleDateRangeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hideTooltip: PropTypes.bool,
  allowClear: PropTypes.bool,
};
