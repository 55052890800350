import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const SUBSCRIBE_URL_IDS = {
  main: {
    39: '28oaFG6bp1RD27m5km',
    99: '8wM5lmeHV2VH7rG5kn',
    299: 'dR6156eHVfIt9zO3cg'
  },
  eu: {
    39: '00g9D05pn1ma1bieUU',
    99: '8wM16u5pn2qe9HOaEF',
    299: 'bIYcPcdVT3uicU0cMO'
  },
};

export default function Subsribe({ auth }) {
  const location = useLocation();
  const { search } = location;
  const { plan } = queryString.parse(search);
  const { profile } = auth;
  const { email, region = 'main' } = profile || {};

  if (email && plan) {
    const url = SUBSCRIBE_URL_IDS[region][plan];
    window.location.replace('https://buy' + '.str' + 'ipe' + '.com/' + url + `?prefilled_email=${email}`);
  } else {
    console.error('No email or plan');
  }

  return (
    <h5>Redirecting...</h5>
  );
}

Subsribe.propTypes = {
  auth: PropTypes.object.isRequired,
};
