import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { ErrorMessage } from '../../../../shared/Components/ErrorMessage.jsx';

export default function StartStopLocationsSelect({ locations = [], onChange, invalidFields }) {
  function onStartLocationChange({ target: { value } }, index) {
    const newLocations = [...locations];
    newLocations[index][0] = value;
    onChange(newLocations);
  }

  function onStopLocationChange({ target: { value } }, index) {
    const newLocations = [...locations];
    newLocations[index][1] = value;
    onChange(newLocations);
  }

  function onRemove(index) {
    const newLocations = [...locations];
    newLocations.splice(index, 1);
    onChange(newLocations);
  }

  function onAdd() {
    const newLocations = [...locations, ['', '']];
    onChange(newLocations);
  }

  return <>
    {locations.map((location, i) => (
      <div key={i}>
        <Form.Item style={{ display: 'inline-block', width: 350 }}>
          <Input
            placeholder='Intersection of 19th Ave and Santiago St, San Francisco'
            value={location[0]}
            onChange={(e) => onStartLocationChange(e, i)}
            status={invalidFields.includes('startLocation') ? 'error' : ''}
          />
          {invalidFields.includes('startLocation') && (
            <ErrorMessage />
          )}
        </Form.Item>&nbsp; - &nbsp;
        <Form.Item style={{ display: 'inline-block', width: 350 }}>
          <Input
            placeholder='Intersection of 19th Ave and Irving St, San Francisco'
            value={location[1]}
            onChange={(v) => onStopLocationChange(v, i)}
            status={invalidFields.includes('stopLocation') ? 'error' : ''}
          />
          {invalidFields.includes('stopLocation') && (
            <ErrorMessage />
          )}
        </Form.Item>&nbsp;&nbsp;&nbsp;
        {locations.length > 1 && <>
          <Button
            type='dashed'
            shape='circle'
            icon={<DeleteOutlined/>}
            onClick={() => onRemove(i)}/>&nbsp;&nbsp;
        </>}
        {i === locations.length - 1 && (
          <Button type='dashed' shape='circle' icon={<PlusCircleOutlined/>} onClick={onAdd}/>
        )}
      </div>
    )) }
  </>;
}

StartStopLocationsSelect.propTypes = {
  locations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  invalidFields: PropTypes.array
};
