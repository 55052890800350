import React from 'react';
import { Trans } from 'react-i18next';

import PlainQueriesSwitch from '../PlainQueriesSwitch';

export default function plainQueriesSwitchFormHeader(task, updateTask, key='customQueries', hideTooltip) {
  const { UISettings = {} } = task;
  const { isCustomQueries } = UISettings;

  const tooltip = hideTooltip ? null : <Trans i18nKey='action.gm.plainQueries'>Plain queries (search keywords, place IDs, URLs, etc.)</Trans>;

  function onSetIsCustomQueries(value) {
    updateTask({ UISettings: { ...UISettings, isCustomQueries: value } });
    localStorage.setItem(key, value ? '1' : '0');
  }

  return <PlainQueriesSwitch checked={!!isCustomQueries} onChange={onSetIsCustomQueries} tooltip={tooltip}/>;
}
