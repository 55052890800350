import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-undef
var ReactS3Uploader = require('react-s3-uploader');

import config from 'react-global-configuration';
import { Progress, notification } from 'antd';

const FILE_UPLOADER_DIR = config.get('fileUploaderDir');

export default function FileUploader({ onFileUploaded }) {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  let uploadInput = useRef();

  function onUploadProgress(progress) {
    setProgress(progress);
    setIsUploading(true);
  }

  function onUploadError(error) {
    setIsUploading(false);
    console.error(error);

    if (error.includes('Status = 401')) {
      localStorage.removeItem('token');
      window.location = '/login';
    }
    notification.error({ message: 'Error while uploading the file' });
  }

  function onUploadFinish({ signedUrl }, { name }) {
    setIsUploading(false);
    onFileUploaded(signedUrl.split('?')[0]);
    notification.success({ message: `Uploaded: ${name}` });
  }

  return <>
    <ReactS3Uploader
      id='uploader'
      signingUrl='/s3/sign'
      signingUrlMethod='GET'
      accept='.csv,.xlsx,.xls,.parquet,.txt'
      style={{ width: '500px', cursor: 'pointer' }}
      s3path={FILE_UPLOADER_DIR}
      onProgress={onUploadProgress}
      onError={onUploadError}
      onFinish={onUploadFinish}
      signingUrlHeaders={{ 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }}
      signingUrlQueryParams={{ 'forceHttp': window.location.protocol === 'http:' }}
      signingUrlWithCredentials={false}
      uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
      contentDisposition='auto'
      scrubFilename={(filename) => filename}
      server={`${window.location.protocol}//${config.get('apiDomain')}`}
      inputRef={uploadInput}
      autoUpload={true}
    />
    {isUploading && <Progress percent={progress} />}
  </>;
}

FileUploader.propTypes = {
  onFileUploaded: PropTypes.func.isRequired
};
