import ReactGA4 from 'react-ga4';
import config from 'react-global-configuration';

// eslint-disable-next-line no-undef
let prodEnv = process.env.NODE_ENV === 'production';

const actions = {
  init: () => {
    try {
      if (prodEnv) {
        ReactGA4.initialize(config.get('gaToken'));
      }

      window.dataLayer = window.dataLayer || [];
    } catch (error) {
      console.error('GA error:', error);
    }
  },
  track: (category, action, label) => {
    try {
      ReactGA4.event({ category, action, label });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'event': `${action} v2` });
    } catch (error) {
      console.error('GA error:', error);
    }
  }
};

export default actions;
