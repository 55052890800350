import React from 'react';

import SimpleService from './Common/SimpleService';

export default function BookingPricesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='hotel'
      title='Booking Prices Scraper'
      subTitle='Returns prices from a list of hotels'
      serviceName='booking_prices_service'
      queriesPlaceholder='https://www.booking.com/hotel/tr/old-town-point-amp-spa-antalya.html&#10;old-town-point-amp-spa-antalya'
      apiTag='Booking/paths/~1booking~1prices/get'
    />
  );
}
