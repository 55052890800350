import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { ErrorMessage } from '../../../../shared/Components/ErrorMessage.jsx';
import { useTranslation } from 'react-i18next';

function CustomItemsSelect({ value, onChange, placeholder, autoFocus, rows = 7, error }) {
  const { t } = useTranslation();

  function handleOnChange({ target }) {
    onChange(target.value);
  }

  const errorMsg = useMemo(() => {
    return value && value.length < 3 ? t('description.invalidValue', 'Invalid value.') : '';
  }, [value]);
  return (
    <>
      <Input.TextArea
        size='large'
        autoFocus={autoFocus}
        rows={rows}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        status={error ? 'error' : ''}
      />
      {error && (
        <ErrorMessage message={errorMsg} />
      )}
    </>
  );
}

CustomItemsSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  rows: PropTypes.number,
  error: PropTypes.bool,
};

export default React.memo(CustomItemsSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value && prevProps.error === nextProps.error;
});
