import React from 'react';
import { Form, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import BaseService from '../Common/BaseService';
import CustomQueries from '../Common/Selects/CustomQueries';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default function WhatsappSender() {
  const { t } = useTranslation();

  return (
    <BaseService
      beta
      title='WhatsApp Sender'
      subTitle='Allows to send messages to a list of phone numbers'
      serviceName='whatsapp_sender'
      startTaskButtonTitle='Send'
      unitName='message'
      limitType={null}
      hasResult={false}
      startTaskButtonIcon={<SendOutlined />}
      taskExtraDefaultParams={{ message: '' }}
      FormBody={({ updateTask, task, invalidFields }) => {
        const { queries, message, inputFile, settings } = task;

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        return <>
          <Form.Item required label={t('title.Phone numbers (international)', 'Phone numbers (international)')} style={{ maxWidth: 600 }}>
            <CustomQueries
              value={queries}
              onChange={(v) => updateTask({ queries: v })}
              inputFile={inputFile}
              onFileUploaded={onFileUploaded}
              placeholder='1 281 236 8208&#10;1 281 236 2248'
              error={invalidFields.includes('queries')}
            />
          </Form.Item>
          <Form.Item required label={t('title.message', 'Message')} style={{ maxWidth: 600 }}>
            <TextArea
              rows={4}
              value={message}
              onChange={({ target }) => updateTask({ message: target.value })}
              placeholder={t('description.message', 'Content of your message')}
              maxLength={6553}
            />
          </Form.Item>
        </>;
      }}
    />
  );
}
