import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Typography, Space, notification } from 'antd';
import Moment from 'react-moment';
import config from 'react-global-configuration';
import { useParams } from 'react-router-dom';

import { services } from '../../shared/data/services';
import { getTags } from '../../utils/utils';
import apiTasks from '../../api/apiTasks';
import TaskResults from './TaskResults';

const { Meta } = Card;
const { Text } = Typography;
const taskExpiredAfter = config.get('taskExpiredAfter');

export default function SharedTaskResults() {
  const { userId, taskId } = useParams();
  const [task, setTask] = useState({});
  const [loading, setLoading] = useState(false);

  const { created, metadata, results } = task;
  const { region, language, enrichments = [] } = metadata || {};
  const tags = getTags(metadata);

  const expired = Date.now() - Date.parse(created) > taskExpiredAfter * 60 * 60 * 24 * 1000;

  useEffect(() => {
    document.title = 'Shared File Result';
    onFetchData();
  }, []);

  async function onFetchData() {
    setLoading(true);
    try {
      const { data } = await apiTasks.fetchSharedTask(userId, taskId);
      setTask(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card style={{ maxWidth: '900px' }} loading={loading}>
      <Meta title={<>
        Shared File Result{tags.length > 0 ? ' : ' + tags.join(' ,') : ''}
        {expired ? <Text type='secondary'> (Expired)</Text> : ''}
      </>}/>
      <br/><br/>
      <Descriptions bordered>
        <Descriptions.Item label='Created'>
          {created ? <Moment utc local format='YYYY/MM/DD HH:mm'>{created}</Moment> : ''}
        </Descriptions.Item>
        {region && <Descriptions.Item label='Region'>
          {region}
        </Descriptions.Item>}
        {language && <Descriptions.Item label='Language'>
          {language.toUpperCase()}
        </Descriptions.Item>}
        {enrichments.length > 0 && <Descriptions.Item label='Enrichments' span={3}>
          {enrichments.map(e => { return services[e] ? services[e].name : e; }).join(', ')}
        </Descriptions.Item>}
      </Descriptions>

      <br/><br/>
      <Space>
        <TaskResults value={results} created={created} size='default' showInfo={false} type='primary' />
      </Space>
    </Card>
  );
}
