import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import config from 'react-global-configuration';

const homeUrl = config.get('homeUrl');

export default function ReferrerRedirect() {
  const location = useLocation();
  const { search } = location;
  const { utm_source, referrer, redirect = '' } = queryString.parse(search);

  if (referrer) localStorage.setItem('referrer', referrer);
  if (utm_source) localStorage.setItem('source', utm_source);

  window.location.replace(redirect ? `https://${homeUrl}/${redirect}` : `https://${homeUrl}`);

  return <h5>Redirecting...</h5>;
}
