import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Dropdown, Input, Row, Col, Checkbox, Empty } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { getServiceUrl } from '../../../utils/utils';
import { services, checkIfNewService, serviceGroups } from '../../../shared/data/services';
import { EnrichmentCard } from './components/EnrichmentCard/EnrichmentCard';
import './EnrichmentListSelector.scss';
import { Filter } from '../../../assets/icons';

function EnrichmentListSelector({ task, onChange, supportedEnrichmentServices, exludedEnrichmentServices,
  serviceOntologies, updateTask
}) {
  const { service_name, enrichments, region } = task;
  const { t } = useTranslation();
  const supportedEnrichmets = useMemo(() => new Set(supportedEnrichmentServices || []), [supportedEnrichmentServices]);
  const supportedOntologies = useMemo(() => getSupportedOntologies(enrichments || []), [enrichments, serviceOntologies]);
  const recentNewServices = useMemo(() => {
    const recentNewServices = localStorage.getItem('recentNewEnrichments');
    return recentNewServices ? JSON.parse(recentNewServices) : [];
  }, []);
  const [selectedServices, setSelectedServices] = useState(enrichments);
  const [searchValue, setSearchValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const isFilterActive = selectedCategories.length > 0;

  useEffect(() => {
    if (enrichments.length > 0) {
      enrichments.forEach(service => {
        const serviceUrl = getServiceUrl(service);
        if (checkIfNewService(serviceUrl, recentNewServices)) markServiceAsViewed(serviceUrl);
      });
    }
  }, [enrichments, recentNewServices]);

  function getSupportedOntologies(value) {
    const selectedServicesSupportedOntologies = [];
    value.forEach(service => {
      const currentService = services[service];
      if (currentService) {
        const outputOntologies = currentService.output_ontologies || [];
        const outputFields = currentService.output_fields || [];
        selectedServicesSupportedOntologies.push(...[...outputOntologies, ...outputFields]);
      }
    });

    return new Set([
      ...(serviceOntologies || []),
      ...selectedServicesSupportedOntologies,
      ...((services[service_name] || {}).input_fields || []),
      ...((services[service_name] || {}).output_fields || []),
      ...((services[service_name] || {}).output_ontologies || []),
    ]);
  }

  const handleOnChange = (newSelected) => {
    const supportedOntologies = getSupportedOntologies(newSelected);
    const filtered = newSelected.filter(v => showService(v, services[v], supportedOntologies));
    setSelectedServices(filtered);
    onChange(filtered);
  };

  const handleOnSelect = (serviceId) => {
    const newSelected = selectedServices.includes(serviceId)
      ? selectedServices.filter(id => id !== serviceId)
      : [...selectedServices, serviceId];

    setSelectedServices(newSelected);
    handleOnChange(newSelected);

    const serviceUrl = getServiceUrl(serviceId);
    if (checkIfNewService(serviceUrl, recentNewServices)) markServiceAsViewed(serviceUrl);
  };

  function showService(inputServiceName, service, ontologies = supportedOntologies) {
    if (!isEmpty(supportedEnrichmets) && supportedEnrichmets.has(inputServiceName)) return true;

    if (inputServiceName === service_name) return false;

    if (region && service.supported_regions && !service.supported_regions.includes(region)) return false;

    if (service.incompatible_with && enrichments.some(v => service.incompatible_with.includes(v))) return false;

    if (!isEmpty(ontologies) && !isEmpty(service.input_fields) && service.input_fields.some(ontology => ontologies.has(ontology)) && (!exludedEnrichmentServices || !exludedEnrichmentServices.includes(inputServiceName))) {
      return true;
    }

    return service.supported_services && service.supported_services.includes(service_name);
  }

  function markServiceAsViewed(serviceUrl) {
    localStorage.setItem('recentNewEnrichments', JSON.stringify([...recentNewServices, serviceUrl]));
  }

  const enrichmentItems = useMemo(() => {
    let newServices = 0;

    const filteredServices = Object.entries(services)
      .filter(([serviceId, service]) => {
        const isInCategory = isEmpty(selectedCategories) || selectedCategories.some(category => service.groups?.includes(category));
        return showService(serviceId, service) && isInCategory;
      })
      .map(([serviceId, service]) => {
        const serviceUrl = getServiceUrl(serviceId);
        const isNewService = checkIfNewService(serviceUrl, recentNewServices) && !enrichments.includes(serviceId);
        if (isNewService) newServices += 1;

        return { ...service, isNewService, id: serviceId };
      })
      .filter(service => service.name.toLowerCase().includes(searchValue.toLowerCase()));

    const totalServices = filteredServices.length;
    const selectedCount = filteredServices.filter(service => selectedServices.includes(service.id)).length;
    filteredServices.sort((a, b) => selectedServices.includes(b.id) - selectedServices.includes(a.id));

    return { items: filteredServices, newServices, totalServices, selectedCount };
  }, [enrichments, recentNewServices, service_name, region, supportedEnrichmets, selectedServices, searchValue, selectedCategories]);

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleCategoryChange = (e, key) => {
    const updatedCategories = e.target.checked
      ? [...selectedCategories, key]
      : selectedCategories.filter((category) => category !== key);
    setSelectedCategories(updatedCategories);
  };

  const categoryFilter = (
    <div className='categoryFilter'>
      {Object.entries(serviceGroups).map(([key, label]) => (
        <Checkbox
          key={key}
          checked={selectedCategories.includes(key)}
          onChange={(e) => handleCategoryChange(e, key)}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  );

  return (
    <>
      <Row gutter={8} className='w-100 mt-8'>
        <Col xs={23} lg={6}>
          <Input
            placeholder={t('title.search', 'Search')}
            allowClear
            size='large'
            onChange={onSearch}
          />
        </Col>
        <Col xs={1}>
          <Dropdown overlay={categoryFilter} trigger={['click']}>
            <Button
              className={`filterBtn ${isFilterActive ? 'active' : ''}`}
              icon={<Filter />}
            />
          </Dropdown>
        </Col>
      </Row>
      <div className='enrichmentList'>
        {enrichmentItems.items.length === 0 ? (
          <Empty description={t('title.noResultsfound', 'No results found')} />
        ) : (
          <>
            <div className='serviceCount'>
              <span className='lowercase'>{enrichmentItems.totalServices } <Trans i18nKey='title.total'>Total</Trans>,</span>
              <span className='lowercase'>{enrichmentItems.selectedCount } <Trans i18nKey='title.selected'>Selected</Trans></span>
            </div>
            <Swiper
              slidesPerView={3.2}
              breakpoints={{
                768: { slidesPerView: 1.2 },
                1024: { slidesPerView: 2.2 },
                1440: { slidesPerView: 3.2 },
                1920: { slidesPerView: 4.2 },
              }}
              spaceBetween={10}
            >
              {enrichmentItems.items.map((service) => (
                <SwiperSlide key={service.id}>
                  <EnrichmentCard
                    task={task}
                    updateTask={updateTask}
                    service={service}
                    handleOnSelect={handleOnSelect}
                    isSelected={selectedServices.includes(service.id)}
                  />
                </SwiperSlide>
              ))}
            </Swiper></>
        )}
      </div>
    </>
  );
}

EnrichmentListSelector.propTypes = {
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  supportedEnrichmentServices: PropTypes.array,
  exludedEnrichmentServices: PropTypes.array,
  serviceOntologies: PropTypes.array,
};

export default React.memo(EnrichmentListSelector, (prevProps, nextProps) => {
  return prevProps.task === nextProps.task;
});
