import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Cron } from 'react-js-cron';

export default function ScheduleModal({ open, onSave, onCancel }) {
  const now = new Date();
  const defaultValue = `${now.getMinutes()} ${now.getHours()} * * *`;
  const [value, setValue] = useState(defaultValue);
  const [error, setError]  = useState();
  const { t } = useTranslation();

  function handlSetValue(value) {
    const items = value.split(' ');

    if (value === '* * * * *' || items[1] === '*') {
      setError('The interval is too small');
    } else {
      setError(null);
    }

    setValue(value);
  }

  return (
    <Modal
      title={t('title.taskScheduler', 'Task Scheduler')}
      okText={t('action.send', 'Send')}
      cancelText={t('action.cancel', 'Cancel')}
      open={open}
      onOk={() => onSave(value)}
      onCancel={onCancel}
      okButtonProps={{ icon: <ClockCircleOutlined />, title: `Schedule task (${value})`, disabled: !!error }}
    >
      <Cron value={value} setValue={handlSetValue} clearButton={false} />
      {error && <><br/><p className='warning'>{error}</p></>}
    </Modal>
  );
}

ScheduleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
};
