import { parseQueries as textToArray, arrayToText } from '../../../../utils/utils';

export { textToArray, arrayToText };

export function formatTaskQueries(task, isCustomQueries, isCustomCategories, isCustomLocations) {
  const formatedTask = { ...task };
  const { queries, input_file, enrich, customCategories, customLocations } = formatedTask;

  if (isCustomQueries || enrich) {
    formatedTask.categories = [];
    formatedTask.locations = [];

    if (input_file) {
      formatedTask.queries = [];
    } else {
      formatedTask.queries = textToArray(queries);
    }
  } else {
    delete formatedTask.input_file;
    formatedTask.queries = [];

    if (isCustomCategories) {
      formatedTask.categories = textToArray(customCategories);
    }

    if (isCustomLocations) {
      formatedTask.locations = textToArray(customLocations);
    }
    formatedTask.enrichLocations = !isCustomLocations;
  }

  delete formatedTask.customCategories;
  delete formatedTask.customLocations;

  return formatedTask;
}

export function deformatTaskQueries(task, isCustomQueries, isCustomCategories, isCustomLocations) {
  const formatedTask = { ...task };
  const { queries, input_file, categories, locations } = formatedTask;

  if (isCustomQueries) {
    formatedTask.categories = [];
    formatedTask.locations = [];

    if (input_file) {
      formatedTask.queries = [];
    } else {
      formatedTask.queries = arrayToText(queries);
    }
  } else {
    formatedTask.queries = [];

    if (isCustomCategories) {
      formatedTask.customCategories = arrayToText(categories);
    }

    if (isCustomLocations) {
      formatedTask.customLocations = arrayToText(locations);
    }
  }

  return formatedTask;
}
