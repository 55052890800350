import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

export default function PrivateRoute({ element: Component, auth, ...rest }) {
  const { authenticated = false } = auth;
  const location = useLocation();

  if (!authenticated) {
    return <Navigate to={`/login?redirect=${location.pathname}${location.search}`} />;
  }

  return <Component {...rest} />;
}

PrivateRoute.propTypes = {
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  auth: PropTypes.shape({
    authenticated: PropTypes.bool,
  }).isRequired,
};
