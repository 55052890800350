import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { usStates } from '../../shared/data/regions';
import SimpleService from './Common/SimpleService';
import RegionSelect from './Common/Google/RegionSelect';
import { defaultWebsiteEnrichments, defaultPhoneEnrichments } from '../../shared/data/services';

const { Option } = Select;

export default function PlacesByDomainsSearch() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='domain'
      defaultLimit={1}
      title='Google Maps Search by Domains'
      subTitle='Finds places by domains'
      serviceName='google_places_domain_search_service'
      queriesPlaceholder='dominopark.com&#10;https://www.esbnyc.com/'
      queriesLabel='Domains or URLs'
      apiTag='Google/paths/~1google-places-by-domain/get'
      supportedEnrichmentServices={[]}
      serviceOntologies={['domain', 'phone', 'address']}
      defaultEnrichments={[ ...defaultWebsiteEnrichments, ...defaultPhoneEnrichments ]}
      ExtraItems={({ updateTask, task }) => {
        const { region, state } = task;

        return <>
          <Form.Item className='formItem'>
            <Form.Item label={t('title.country', 'Country')} className='inlineFormItem'>
              <RegionSelect size='default' value={region} onChange={(value) => updateTask({ region: value })}/>
            </Form.Item>
            {region === 'US' &&
              <Form.Item label={t('title.state', 'State')} className='inlineFormItem'>
                <Select
                  allowClear
                  showSearch
                  style={{ minWidth: 180 }}
                  size='large'
                  value={state}
                  onChange={(value) => updateTask({ state: value })}
                  optionFilterProp='key'
                >
                  {usStates.map(r => <Option key={`${r.code}-${r.state}`} value={r.code}>{r.state}</Option>)}
                </Select>
              </Form.Item>
            }
          </Form.Item>
        </>;
      }}
    />
  );
}
