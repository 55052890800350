import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag, Empty, Badge } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import ReactJson from 'react-json-view';

import { trimTaskMetadata, getTags } from './../../utils/utils';
import { removeScheduledTask, pauseScheduledTask, resumeScheduledTask, fetchScheduledTasks } from '../../redux/actions/tasksActions';
import ScheduledTaskActions from './ScheduledTaskActions';

export default function ScheduledTasksTable({ selected = false, refresh = 0 }) {
  const scheduledTasks = useSelector(state => state.tasksReducer.scheduledTasks);
  const loading = useSelector(state => state.tasksReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const scheduledTasksColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('title.tags', 'Tags'),
      dataIndex: 'metadata',
      key: 'tags',
      render: (metadata) => <>{getTags(metadata).map(tag => (<Tag key={tag}>{tag}</Tag>))}</>,
    },
    {
      title: 'Crontab',
      dataIndex: 'crontab',
      key: 'crontab',
    },
    {
      title: t('title.nextRun', 'Next run'),
      dataIndex: 'next_run',
      key: 'next_run',
      render: next_run => next_run && <Moment local format='YYYY/MM/DD HH:mm'>{next_run}</Moment>,
    },
    {
      title: t('title.status', 'Status'),
      dataIndex: '',
      key: 'status',
      render: (_, record) => <Badge status={record.next_run ? 'processing': 'error'} text={record.next_run ? t('title.active', 'Active'): t('title.paused', 'Paused')} />,
    },
    {
      title: t('title.actions', 'Actions'),
      dataIndex: 'id',
      key: 'actions',
      render: (id, record) => <ScheduledTaskActions
        task={record}
        onRemove={onRemoveTask}
        onPause={onPauseTask}
        onResume={onResumeTask}
      />
    },
  ];

  useEffect(() => {
    document.title = 'Scheduled Tasks';
    if (selected) dispatch(fetchScheduledTasks());
  }, [selected, refresh]);

  async function onRemoveTask(taskId) {
    await dispatch(removeScheduledTask(taskId));
    await dispatch(fetchScheduledTasks());
  }

  async function onPauseTask(taskId) {
    await dispatch(pauseScheduledTask(taskId));
    await dispatch(fetchScheduledTasks());
  }

  async function onResumeTask(taskId) {
    await dispatch(resumeScheduledTask(taskId));
    await dispatch(fetchScheduledTasks());
  }

  return (
    <Table
      rowKey='id'
      loading={loading}
      columns={scheduledTasksColumns}
      dataSource={scheduledTasks}
      expandable={{ expandedRowRender: task => <ReactJson src={trimTaskMetadata(task.metadata)} name='metadata'/> }}
      locale={{ emptyText: <Empty description={<Trans i18nKey='description.emptyScheduledTasks'>You have no scheduled tasks yet. You can schedule a task by clicking on the clock <ClockCircleOutlined /> button.</Trans>} /> }}
    />
  );
}

ScheduledTasksTable.propTypes = {
  selected: PropTypes.bool,
  refresh: PropTypes.number,
};
