import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from '@ant-design/pro-layout';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

import Href from '../../../shared/Components/Href';
import { getServiceUrl } from './../../../utils/utils';
import { newServices  } from '../../../shared/data/services';

const starStyle = { color: '#FFD700' };

function ExtractorHeader({ title, localTitle, href, subTitle, serviceName }) {
  const navigate = useNavigate();
  const location = useLocation();
  const starredServices = localStorage.getItem('starredServices');
  const starredServicesArray = starredServices ? JSON.parse(starredServices) : [];
  const indexOfStarredService = starredServicesArray.findIndex(
    (service) => service.title === title
  );
  const [starred, setStarred] = useState(indexOfStarredService !== -1);
  const serviceUrl = getServiceUrl(serviceName);

  function onStarClick() {
    if (starred) {
      starredServicesArray.splice(indexOfStarredService, 1);
    } else {
      starredServicesArray.push({ title, href: serviceUrl });
      if (starredServicesArray.length > 10) starredServicesArray.shift();
    }

    setStarred(!starred);
    localStorage.setItem('starredServices', JSON.stringify(starredServicesArray));
    navigate(`${location.pathname}${location.search}`, { replace: true });
  }

  useEffect(() => {
    const recentServices = localStorage.getItem('recentServices');
    const recentNewServices = localStorage.getItem('recentNewServices');
    const recentServicesArray = recentServices ? JSON.parse(recentServices) : [];
    const recentNewServicesArray = recentNewServices ? JSON.parse(recentNewServices) : [];
    const serviceUrl = getServiceUrl(serviceName);

    if (!recentServicesArray.some(service => service.title === title)) {
      recentServicesArray.push({ title, href: serviceUrl });
      if (recentServicesArray.length > 10) recentServicesArray.shift();
      localStorage.setItem('recentServices', JSON.stringify(recentServicesArray));
    }

    if (serviceUrl in newServices && !recentNewServicesArray.includes(serviceUrl)) {
      localStorage.setItem('recentNewServices', JSON.stringify([...recentNewServicesArray, serviceUrl]));
    }
  }, []);

  return <>
    <PageHeader
      subTitle={subTitle ? <>
        <h1>{localTitle} {serviceName && (starred ?
          <StarFilled title='unstar' onClick={onStarClick} style={starStyle}/> :
          <StarOutlined title='star' onClick={onStarClick} style={starStyle}/>)
        }</h1>{subTitle}
      </> : <Href href={href} external>learn more</Href>}
    /><br/>
  </>;
}

ExtractorHeader.propTypes = {
  title: PropTypes.string.isRequired,
  localTitle: PropTypes.string.isRequired,
  href: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  serviceName: PropTypes.string,
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.title === nextProps.title;
};

export default React.memo(ExtractorHeader, areEqual);
