import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import config from 'react-global-configuration';

import { isRecentlyUsedNTimes } from '../../../utils/utils';
import MakePaymentButton from './MakePaymentButton';
import { nmiCharge } from '../../../redux/actions/profileActions';

const hcaptchaSiteKey = config.get('hcaptchaSiteKey');

export default function MakePaymentWithNMIButton({ loading, callback, amount, title = 'Card', disabled = false, riskLevel = 0 }) {
  const [loadingInternal, setLoadingInternal] = useState(false);
  const hcaptchaRef = useRef(null);

  useEffect(() => {
    if (window.CollectJS) {
      window.CollectJS.configure({
        variant: 'lightbox',
        callback: (response) => {
          onFinishSubmit(response);
        }
      });
    }
  }, [amount]);

  async function onSuccess() {
    if (callback) {
      await new Promise(r => setTimeout(r, 3000));
      callback();
    }
  }

  async function onFinishSubmit({ token }) {
    setLoadingInternal(true);
    await nmiCharge(token, amount, onSuccess);
    setLoadingInternal(false);
  }

  function onSubmit() {
    if (callback) {
      callback();
    }

    setLoadingInternal(true);
    setTimeout(() => {
      setLoadingInternal(false);
    }, 1500);
    window.CollectJS.startPaymentRequest();
  }

  function handleCaptchaVerify() {
    onSubmit();
  }

  function handleOnSubmit() {
    if (riskLevel > 1 || isRecentlyUsedNTimes('np')) {
      hcaptchaRef.current.execute();
    } else {
      onSubmit();
    }
  }

  return <>
    <MakePaymentButton
      loading={loading || loadingInternal}
      onMakePayment={handleOnSubmit}
      title={title}
      disabled={disabled}
    />
    <HCaptcha
      ref={hcaptchaRef}
      size='invisible'
      sitekey={hcaptchaSiteKey}
      onVerify={handleCaptchaVerify}
    />
  </>;
}

MakePaymentWithNMIButton.propTypes = {
  callback: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  amount: PropTypes.number,
  riskLevel: PropTypes.number,
};
