import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification, message, Input, Card, Form, InputNumber, Button, Row, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import Testimonials from '../../../../shared/Components/Testimonials';
import apiPayment from '../../../../api/apiPayment';

const StyledInput = styled(Input)`
  width: 200px;
`;

const StyledAddressInput = styled(Input)`
  width: 400px;
  background-color: #f5fcff;
`;

const { Meta } = Card;

export default function CryptoPayment() {
  const { paymentId } = useParams();

  const [paymentDetails, setPaymentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { payment_status, pay_address, actually_paid, price_amount, price_currency, pay_amount, pay_currency } = paymentDetails;
  const payCurrency = pay_currency ? pay_currency.toUpperCase() : '';
  const priceCurrency = price_currency ? price_currency.toUpperCase() : '';
  const description = <Trans i18nKey='description.sendCrypto'>Please send <strong>{{ pay_amount }}</strong> {{ payCurrency }} (<strong>exact amount</strong>) to the deposit address displayed below. The deposit will be credited to your account balance.</Trans>;
  const payCurrencyAddress = <Trans i18nKey='description.cryptoAddress'>{{ payCurrency }} address</Trans>;
  const isPaid = actually_paid > 0;

  useEffect(() => {
    if (paymentId) {
      fetchData();

      setInterval(() => {
        fetchData(false);
      }, 30000);
    }
  }, [paymentId]);

  async function fetchData(showLoading = true) {
    if (showLoading) setLoading(true);
    try {
      const { data } = await apiPayment.getCryptoPayment(paymentId);
      setPaymentDetails(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      if (showLoading) setLoading(false);
    }
  }

  function onCopy(selector, copyText = '') {
    var element = document.querySelector(selector);
    element.select();
    document.execCommand('copy');

    message.success(`${copyText} ` + t('description.copied', 'Copied to your clipboard'));
  }

  return (
    <Row>
      <Col flex='auto'>
        <Card
          title={`${t('title.payment', 'Payment')} #${paymentId} [${payment_status ? t(`title.${payment_status}`, payment_status) : '...'}]`}
          extra={<Button loading={loading} onClick={fetchData} type='default'><Trans i18nKey='title.refresh'>Refresh</Trans></Button>}
          style={{ maxWidth: 450 }}
        >
          <Meta description={isPaid ? <Trans i18nKey='description.cryptoDeposited'>
            You successfully deposited <strong>{{ actually_paid }} {{ payCurrency }}</strong>.
            Navigate to the profile page to see your account balance (it might take up to <strong>10 minutes</strong> to confirm your transaction).
          </Trans> : description} />
          <br/>
          <Form name='basic' layout='vertical'>
            <Form.Item label={t('title.amount', 'Amount') + ' ' + payCurrency}>
              <StyledInput
                readOnly
                className='pay-amount'
                value={pay_amount}
                onClick={() => onCopy('.pay-amount', t('title.amount', 'Amount') + ' ' + payCurrency)}
                disabled={loading}
              />
            </Form.Item>
            <Form.Item label={t('title.amount', 'Amount') + ' ' + priceCurrency}>
              <StyledInput
                readOnly
                className='price-amount'
                value={price_amount}
                onClick={() => onCopy('.price-amount', t('title.amount', 'Amount') + ' ' + priceCurrency)}
                disabled={loading}
              />
            </Form.Item>
            <Form.Item label={payCurrencyAddress}>
              <StyledAddressInput
                readOnly
                className='pay-address'
                value={pay_address}
                onClick={() => onCopy('.pay-address')}
                disabled={loading}
              />
            </Form.Item>
            {isPaid &&
              <Form.Item label={t('title.amountPaid', 'Amount Paid') + ' ' + payCurrency}>
                <InputNumber
                  readOnly
                  style={{ width: 250 }}
                  className='actually-paid'
                  value={actually_paid}
                  disabled={loading}
                />
              </Form.Item>
            }
          </Form>
        </Card>
      </Col>
      <Col offset='auto'><Testimonials/></Col>
    </Row>
  );
}

