import React, { useEffect } from 'react';
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';
import { useParams } from 'react-router-dom';

import apiProfile from '../api/apiProfile';

export default function VerifyEmail() {
  const { token } = useParams();
  const payload = jwt_decode(token);
  const { customer_email } = payload || {};

  useEffect(() => {
    document.title = 'Verifying email...';

    const verifyEmail = async () => {
      if (token) {
        try {
          await apiProfile.verifyEmail(token);
          localStorage.setItem('emailVerified', 'true');
          notification.success({ message: 'Email verified' });
        } catch (error) {
          notification.error({ message: error.message });
        }

        setTimeout(() =>  window.location = `/login?email=${customer_email}`, 2500);
      }
    };

    verifyEmail();
  }, []);

  return <h5>Loading...</h5>;
}
