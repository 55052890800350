import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import apiProfile from '../api/apiProfile';
import LoginSignupForm from './Common/LoginSignupForm';
import EmailFormItem from './Common/EmailFormItem';

export default function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [checkEmail, setCheckEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (window.top !== window.self) {
    window.top.location = window.location;
  }

  async function onFinish(payload){
    setLoading(true);

    try {
      const { email } = payload;
      await apiProfile.requestPasswordChange(email);
      notification.success({ message: t('title.checkInbox', 'Check Your Inbox') });
      setCheckEmail(email);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  function back(){
    navigate('/login');
  }

  return <LoginSignupForm
    captcha
    title={t('title.forgotPassword', 'Forgot Password?')}
    subTitle={t('description.EnterEmail', 'Enter your email address and we will send you instructions')}
    loading={loading}
    location={location}
    onFinish={onFinish}
    checkEmail={checkEmail}
    AfterButtonComponent={() => <Button type='link' style={{ marginLeft: -15 }} onClick={back}><Trans i18nKey='title.logIn'>Log In</Trans></Button>}
  >
    <EmailFormItem />
  </LoginSignupForm>;
}
