import { isEmpty } from 'lodash';

export function validateCategoryLocationExpanded(task) {
  const { UISettings, queries, customCategories, categories, customLocations, locations, input_file } = task;
  const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;
  const invalidFields = [];

  if (input_file) {
    return { isValid: true, invalidFields: [] };
  }

  if (isCustomQueries) {
    if (queries.length < 3) {
      invalidFields.push('queries');
    }
  } else {
    if (isCustomCategories) {
      if (!customCategories || customCategories.length < 3) {
        invalidFields.push('customCategories');
      }
    } else {
      if (categories.length === 0) {
        invalidFields.push('categories');
      }
    }

    if (isCustomLocations) {
      if (!customLocations || customLocations.length < 3) {
        invalidFields.push('customLocations');
      }
    } else {
      if (locations.length === 0) {
        invalidFields.push('locations');
      }
    }
  }

  return { isValid: invalidFields.length === 0, invalidFields };
}

export function validateIntervalDateFrameExpanded(task) {
  const { UISettings, queries, interval, dateFrame } = task;
  const { isCustomQueries } = UISettings;
  const invalidFields = [];

  if (!interval) {
    invalidFields.push('interval');
  }
  if (!dateFrame || dateFrame.length !== 2) {
    invalidFields.push('dateFrame');
  }

  if (isCustomQueries) {
    if (queries.length < 3) {
      invalidFields.push('queries');
    }
  } else {
    queries.forEach(([start, stop]) => {
      if (start === '' && stop === '') {
        invalidFields.push('startLocation');
        invalidFields.push('stopLocation');
      } else {
        if (start === '') {
          invalidFields.push('startLocation');
        }
        if (stop === '') {
          invalidFields.push('stopLocation');
        }
      }
    });
  }

  return { isValid: invalidFields.length === 0, invalidFields };
}

export function validateDefault(task) {
  const { queries, input_file } = task;
  const invalidFields = [];

  if (input_file) {
    return { isValid: true, invalidFields: [] };
  }
  if (queries.length < 3) {
    invalidFields.push('queries');
  }

  return { isValid: invalidFields.length === 0, invalidFields };
}

export function validateTaskAttributesExpanded(task) {
  const { queries, input_file } = task;
  const invalidFields = [];

  if (input_file) {
    return { isValid: true, invalidFields: [] };
  }
  if (queries.length < 3) {
    invalidFields.push('queries');
  }

  if (isEmpty(task.attributes)) {
    invalidFields.push('attributes');
  }

  return { isValid: invalidFields.length === 0, invalidFields };
}
